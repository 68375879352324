import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import { AddButton, SelectInput, TextAreaInput, TextInput } from "../Common/FormComponents";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addJob, getCategories } from "../../utils/careerUtils/careerUtils";
import { toast } from "react-toastify";
import { validateCareerForm } from "../../utils/careerUtils/validateCareerForm";
import FileInput from "../Common/FormComponents/FileInput/FileInput";
import ShowFile from "../Common/FormComponents/ShowFile/ShowFile";

const CareerModal = ({ setOpen, editData = null, setEditData, setJobsFn }) => {
  const initialState = {
    job_title: editData?.job_title || "",
    job_catrgory: editData?.job_catrgory?.job_category || "",
    qualification: editData?.qualification || "",
    experience: editData?.experience || "",
    vacancy: editData?.vacancy || "",
    salary: editData?.salary || "",
    job_responsibilites: editData?.job_responsibilites || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const [file, setFile] = useState(editData?.carrer_upload || null);
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] =
    useForm(metaTagInitialState);
  const keySkillInitailState = { keySkill: "" };
  const [keySkillFormData, handleKeySkillFormData, clearKeySkillForm] =
    useForm(keySkillInitailState);
  const [key_skills, setKeySkills] = useState(editData?.key_skills || []);
  const handleKeySkills = (action, idx = 0) => {
    if (action === "add") {
      if (!keySkillFormData.keySkill.trim().length) {
        return setError({ keySkill: "Please Enter Key Skill" });
      } else {
        setKeySkills([...key_skills, keySkillFormData.keySkill]);
        clearKeySkillForm();
      }
    } else if (action === "remove") {
      setKeySkills(key_skills.filter((_, index) => index !== idx));
    }
  };
  const [err, setError] = useState({ global: "", keySkill: "" });
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setError({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [cats, setCats] = useState([]);
  const [catId, setCatId] = useState(0);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategories()
      .then((res) => {
        setCategories([
          { job_category: "Select Category", _id: 0 },
          ...res.data.data,
        ]);
        setCats([
          "Select Category",
          ...res.data.data.map((item) => item.job_category),
        ]);
      })
      .catch((err) => {
        setCategories([{ job_category: "Select Category", _id: 0 }]);
      });
  }, []);
  useEffect(() => {
    const foundCategory = categories.find(
      (dep) => dep.job_category === formData.job_catrgory
    );
    if (foundCategory) {
      setCatId(foundCategory._id);
    }
  }, [categories, formData.job_catrgory]);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateCareerForm(formData,catId, key_skills, file, setError)) {
      setError({ global: "", keySkill: "" });
      setLoading(true);
      addJob(formData,catId, key_skills, file, metaTags, editData?._id)
        .then((res) => {
          clearForm();
          toast(`Job ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          setJobsFn();
          setOpen(false);
        })
        .catch((err) => {
          toast(`Job ${editData ? "edit" : "add"} failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Job" : "Add New Job"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Title"}
            formName={"job_title"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <div className="feild-conatainer">
            <SelectInput
              data={formData}
              handleInput={handleInput}
              items={cats}
              name={"Category"}
              formName={"job_catrgory"}
              setError={setError}
            />
          </div>
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Qualification"}
            formName={"qualification"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Experience"}
            formName={"experience"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Vacancy"}
            formName={"vacancy"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Salary"}
            formName={"salary"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Responsibilities"}
            formName={"job_responsibilites"}
            setError={setError}
          />
        </div>
        {key_skills?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {key_skills?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleKeySkills("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            data={keySkillFormData}
            handleInput={handleKeySkillFormData}
            name={"Key Skill"}
            formName={"keySkill"}
            setError={setError}
          />
          <AddButton handleAdd={handleKeySkills} />
        </div>
        {err.keySkill && <div className="error-message">{err.keySkill}</div>}
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowFile file={file} setFile={setFile} />
          <FileInput fileName={"Document"} setFile={setFile} />
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setError}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setError}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerModal;
