import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AddButton, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addContactUsContent } from "../../utils/contactUsUtils/contactUsUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";

const ContactUsContentModal = ({ setOpen, setDatasFn, editData = null }) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [officeHours, setOfficeHours] = useState(editData?.office_hours || []);
  const officeHoursInitailsState = { days: "", time: "" };
  const [officeHoursFormData, handleOfficeHoursFromData, clearOfficeHoursFormData] =
    useForm(officeHoursInitailsState);
  const handleOfficeHours = (action, idx = 0) => {
    if (action === "add") {
      if (!officeHoursFormData.days.trim().length || !officeHoursFormData.time.trim().length) {
        return setErr({ global: "Please Enter Days and Time" });
      } else {
        setOfficeHours([...officeHours, officeHoursFormData]);
        clearOfficeHoursFormData();
      }
    } else if (action === "remove") {
      setOfficeHours(officeHours.filter((_, index) => index !== idx));
    }
  };
  const [getInTouch, setGetInTouch] = useState(editData?.get_in_touch || []);
  const getInTouchInitailsState = { contact: "" };
  const [getInTouchFormData, handleGetInTouchFromData, clearGetInTouchFormData] =
    useForm(getInTouchInitailsState);
  const handleGetInTouch = (action, idx = 0) => {
    if (action === "add") {
      if (!getInTouchFormData.contact.trim().length) {
        return setErr({ global: "Please Enter Contact" });
      } else {
        setGetInTouch([...getInTouch, getInTouchFormData.contact]);
        clearGetInTouchFormData();
      }
    } else if (action === "remove") {
      setGetInTouch(getInTouch.filter((_, index) => index !== idx));
    }
  };

  const initialsState = {
    sectionName: editData?.sectionName || "",
    title: editData?.title || "",
    description: editData?.description || "",
    office_address: editData?.office_address || "",
    office_address_2: editData?.office_address_2 || "",
  };

  const [formData, handleFormData] = useForm(initialsState);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErr({ global: "" });
    setLoading(true);

    const submitData = {
      ...formData,
      office_hours: officeHours,
      get_in_touch: getInTouch,
    };

    addContactUsContent(submitData, editData?._id)
      .then((res) => {
        setDatasFn();
        setOpen(false);
        toast(`Contact Us Content Information ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Contact Us Content Information ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit rounded-md justify-start items-start bg-white pb-10">
      <div className="flex w-full max-w-[600px] overflow-y-auto max-h-[80vh] border shadow-lg bg-white rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Contact Us Content Information"}</span>
          <span className="cursor-pointer" onClick={() => setOpen(false)}>
            <GrFormClose fontSize={16} />
          </span>
        </div>

        <TextInput
          hide={true}
          data={formData}
          formName="sectionName"
          name="Section Name"
          handleInput={handleFormData}
          setError={setErr}
        />
        <TextInput
          hide={true}
          data={formData}
          formName="title"
          name="Title"
          handleInput={handleFormData}
          setError={setErr}
        />

        <TextInput
          hide={true}
          data={formData}
          formName="description"
          name="Description"
          handleInput={handleFormData}
          setError={setErr}
          textarea
        />
        <TextInput
          hide={true}
          data={formData}
          formName="office_address"
          name="Office Address"
          handleInput={handleFormData}
          setError={setErr}
        />

        <TextInput
          hide={true}
          data={formData}
          formName="office_address_2"
          name="Office Address 2"
          handleInput={handleFormData}
          setError={setErr}
        />
        {officeHours?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {officeHours?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs "
                key={idx}
              >
                <span className="">{item.days}</span>
                <span className="">{item.time}</span>
                <button
                  onClick={() => {
                    handleOfficeHours("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            data={officeHoursFormData}
            handleInput={handleOfficeHoursFromData}
            name={"Days"}
            formName={"days"}
            setError={setErr}
          />
          <TextInput
            data={officeHoursFormData}
            handleInput={handleOfficeHoursFromData}
            name={"Time"}
            formName={"time"}
            setError={setErr}
          />
          <AddButton handleAdd={handleOfficeHours} />
        </div>
        {getInTouch?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {getInTouch?.map((item, idx) => (
              <div className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs " key={idx}>
                <span className="">{item}</span>
                <button onClick={() => handleGetInTouch("remove", idx)}>
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            data={getInTouchFormData}
            handleInput={handleGetInTouchFromData}
            name={"Get In Touch Number"}
            formName={"contact"}
            setError={setErr}
          />
          <AddButton handleAdd={handleGetInTouch} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}

        <div className="flex w-full h-fit justify-between gap-5">
          <button
            className="w-full border border-primary py-1 rounded-md bg-white text-primary text-shadow-black flex justify-center items-center"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            className="w-full border bg-primary py-1 rounded-md text-white border-primary text-shadow-black flex justify-center items-center"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUsContentModal;