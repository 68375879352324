import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { ImageInput, ShowImage, TextAreaInput, TextInput, SelectInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addAboutValue } from "../../utils/aboutValueUtils/aboutValueUtils";

const AboutValueModal = ({
  setOpen,
  setDatasFn,
  editData = null,
  setEditData,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [about_value_image, setValueImage] = useState(
    editData?.about_value_image || null
  );
  const initialsState = {
    title: editData?.title || "",
    subtitle: editData?.subtitle || "",
    description: editData?.description || "",
    type: editData?.type || "Select Type",
  };
  const [formData, handleFormData] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (!formData.subtitle.trim().length)
      return setErr({ global: "Invalid Subtitle" });
    if (formData.type === "Select Type")
      return setErr({ global: "Please Select Type" });
    if (!formData.description.trim().length)
      return setErr({ global: "Invalid Description" });
    if (!about_value_image) return setErr({ global: "Please Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addAboutValue(formData, about_value_image, editData?._id)
      .then((res) => {
        setDatasFn();
        setValueImage(null);
        setOpen(false);
        toast(`About Value ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`About Value ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg bg-white rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{"About Value"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"title"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <SelectInput
            data={formData}
            formName={"type"}
            name="Type"
            items={["Select Type", "Choose us", "About value"]}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            data={formData}
            formName={"subtitle"}
            name="Subtitle"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextAreaInput
            data={formData}
            formName={"description"}
            name="Description"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"About Value Image"}
            dimension="Width: 634px, Height: 633px"
            setImage={setValueImage}
          />
          <ShowImage image={about_value_image} setImage={setValueImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutValueModal;
