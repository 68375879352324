import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addContactUsNumbers } from "../../utils/contactUsUtils/contactUsUtils";

const ContactUsNumbersModal = ({
  setOpen,
  setDatasFn,
  editData = null,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const initialsState = {
    appointment_call: editData?.appointment_call || "",
    emergency_call: editData?.emergency_call || "",
    whatsapp: editData?.whatsapp || "",
  };
  const [formData, handleFormData] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    setErr({ global: "" });
    setLoading(true);
    addContactUsNumbers(formData, editData?._id)
      .then((res) => {
        setDatasFn();
        setOpen(false);
        toast(`Contact Us Numbers ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(
          `Contact Us Numbers ${editData ? "Edit Failed" : "Add Failed"}`,
          {
            type: "error",
            autoClose: 3000,
          }
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        className=" flex  w-full max-w-[400px] overflow-y-auto max-h-[400px] border shadow-lg bg-white rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{"Contact Us Numbers"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            hide={true}
            data={formData}
            formName={"appointment_call"}
            name="Appointment Call Number"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            hide={true}
            data={formData}
            formName={"emergency_call"}
            name="Emergency Call Number"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            hide={true}
            data={formData}
            formName={"whatsapp"}
            name="Whatsapp Number"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsNumbersModal;
