import React, { useState } from 'react';
import {GrFormClose} from "react-icons/gr"
import { useForm } from '../../hooks';
import {  TextInput } from '../Common/FormComponents';
import { toast } from "react-toastify";
import { addBranch } from '../../utils/branchesUtils/branchesUtils';


const BranchModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setDatasFn,
}) => {
  const initialState = {
    Branch: editData?.Branch || "",
  };
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.Branch.trim().length) {
      setErr({ global: "" });
      setLoading(true);
      addBranch(formData, editData?._id)
        .then((res) => {
          setDatasFn();
          clearForm();
          setEditData(null);
          setOpen(false);
          toast(`Branch ${editData ? `Edited` : `Added`}`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          toast(`Branch ${editData ? `Edit` : `Add`} Failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErr({ global: "Invalid Branch" });
      return;
    }
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        className="flex  w-full max-w-screen-sm bg-white overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{editData ? "Edit Branch" : "Add New Branch"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"Branch"}
            name={"Branch"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchModal