import React, { useCallback, useEffect, useState } from 'react'
import { SearchInput } from '../Common';
import ContactUsTable from './ContactUsTable';
import { getContactFormSubmissions } from '../../utils/leadsUtils/leadsUitls';
import { useAuth } from '../../context/useAuth';
import ContactUsModal from './ContactUsModal';

const LeadsMainSection = () => {
  const user = useAuth().authState.user;
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getContactFormSubmissions().then(res => setDatas(res.data.data)).catch(err=>console.log(err));
  }, [])
  useEffect(() => setDatasFn(), [setDatasFn]);
  const [editData, setEditData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = datas?.filter((lead) => {
      const nameMatch = lead?.name?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const emailMatch = lead?.email?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      return nameMatch || emailMatch;
    });
    setFilteredData(filteredData);
  }, [datas, searchQuery]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDatasFn();
    }, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [setDatasFn]);
    return (
      <>
        {editData && <ContactUsModal setEditData={setEditData} editData={editData} setDatasFn={setDatasFn}/> }
        <div className=" flex w-full items-center gap-5 flex-col">
          <div className="flex w-fit h-fit text-xl font-semibold">Contact Submissions</div>
          <div className="flex w-full h-full justify-center items-center">
            <div className="flex w-full md:w-1/2">
              <SearchInput
                placeholder={"Search By Name, Email"}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full p-3 flex-col">
          <div className="flex w-full justify-start items-center h-fit">
            <span className="font-semibold text-base">Informations</span>
          </div>
          <ContactUsTable user={user} leads={filteredData} setDatasFn={setDatasFn} setEditData={setEditData} />
        </div>
      </>
    );
}

export default LeadsMainSection