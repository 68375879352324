import { axiosAuthorized } from "../../api/config"

export const getAboutHeaderSection = async () => {
  try {
    const response = await axiosAuthorized.get(`/head/get_header`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addAboutHeaderSection = async (initialState, header_image, list_item_text, list_item_image, nav_link_image, id = null) => {
  try {
    const data = {
      ...initialState,
      list_item_text,
    };

    const requestData = id ? data : {
      ...data,
      header_image,
      list_item_image,
      nav_link_image
    }

    const requestConfig = {
      headers: { "Content-Type": id ? "application/json" : "multipart/form-data" },
    };

    const url = id
      ? `/head/update_header/${id}`
      : `/head/create_header`;

    const mainRequest = axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (id && header_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/updateheaderimage/${id}`, { header_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (id && list_item_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/updatelinkimage/${id}`, { list_item_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (id && nav_link_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/updatenavlink/${id}`, { nav_link_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAboutHeaderSection = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/head/delete_header/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}