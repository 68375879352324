import { axiosAuthorized } from "../../api/config"

export const getMissionAndVision = async () => {
  try {
    const response = await axiosAuthorized.get(`/aboutmission/get_aboutmission`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addMissionAndVision = async (initialState, precentage, id = null) => {
  try {
    const requestData = {
      ...initialState,
      precentage
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/aboutmission/update_aboutmission/${id}`
      : `/aboutmission/create_aboutmission`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteMissionAndVision = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/aboutmission/delete_aboutmission/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}