import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  AddButton,
  ImageInput,
  ShowImage,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addAboutHeaderSection } from "../../utils/aboutHeaderSectionUtils/aboutHeaderSectionUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";

const AboutHeaderSectionModal = ({
  setOpen,
  setDatasFn,
  editData = null,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [header_image, setHeaderImage] = useState(
    editData?.header_image || null
  );
  const [list_item_image, setListItemImage] = useState(
    editData?.list_item_image || null
  );
  const [nav_link_image, setNavLinkImage] = useState(
    editData?.nav_link_image || null
  );
  const initialsState = {
    header_title: editData?.header_title || "",
    nav_link_text: editData?.nav_link_text || "",
    nav_link_slug: editData?.nav_link_slug || "",
  };
  const listItemsFormInitialState = { text: "" };
  const [listItemsForm, handleListItemsForm, clearListItemsForm] = useForm(listItemsFormInitialState);
  const [listItems, setListItems] = useState(editData?.list_item_text || []);
  const handleListItems = (action, idx = 0) => {
    if (action === "add") {
      if (!listItemsForm.text.trim().length) {
        return setErr({ global: "Please Enter Text" });
      } else {
        setListItems([...listItems, listItemsForm.text]);
        clearListItemsForm();
      }
    } else if (action === "remove") {
      setListItems(listItems.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleFormData] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.header_title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (!header_image) return setErr({ global: "Please Select Header Image" });
    setErr({ global: "" });
    setLoading(true);
    addAboutHeaderSection(formData, header_image, listItems,  list_item_image, nav_link_image, editData?._id)
      .then((res) => {
        setDatasFn();
        setHeaderImage(null);
        setListItemImage(null);
        setOpen(false);
        toast(`About Header Section ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(
          `About Header Section ${editData ? "Edit Failed" : "Add Failed"}`,
          {
            type: "error",
            autoClose: 3000,
          }
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg bg-white rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{"About Header Section"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"header_title"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"Header Image"}
            setImage={setHeaderImage}
          />
          <ShowImage image={header_image} setImage={setHeaderImage} />
        </div>
        {listItems?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {listItems?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleListItems("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            hide={true}
            data={listItemsForm}
            formName={"text"}
            name="Text"
            handleInput={handleListItemsForm}
            setError={setErr}
          />
          <AddButton handleAdd={handleListItems} />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"List Item Image"}
            setImage={setListItemImage}
          />
          <ShowImage image={list_item_image} setImage={setListItemImage} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            hide={true}
            data={formData}
            formName={"nav_link_text"}
            name="Nav Link Text"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            hide={true}
            data={formData}
            formName={"nav_link_slug"}
            name="Nav Link Slug"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"Nav Link Image"}
            setImage={setNavLinkImage}
          />
          <ShowImage image={nav_link_image} setImage={setNavLinkImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHeaderSectionModal;
