import { axiosAuthorized } from "../../api/config";

export const getSchedule = async (doctorId) => {
    try {
        const response = await axiosAuthorized.get(`/doctorschedule/${doctorId}`);
        return response;
    } catch (error) {
        return error;
    }
}

function convertTimeStringToMinutes(timeString) {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':').map(Number);
    const adjustedHours = period === 'PM' && hours !== 12 ? hours + 12 : hours;
    return adjustedHours * 60 + minutes;
}

function convertMinutesToTimeString(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
    return `${adjustedHours}:${mins < 10 ? '0' : ''}${mins} ${period}`;
}

const generateTimeSlots = (start, end, gap) => {
    const timeSlots = [];
    let currentTime = convertTimeStringToMinutes(start);
    const endTime = convertTimeStringToMinutes(end);

    while (currentTime <= endTime) {
        const formattedTime = convertMinutesToTimeString(currentTime);
        timeSlots.push({ time: formattedTime, isBooked: false });
        currentTime += gap;
    }

    return timeSlots;
};

const isDateInDays = (date, days) => {
    const dayOfWeek = date.getDay();
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days.includes(dayNames[dayOfWeek]);
};

export const generateSchedule = (startDate, endDate, days, startTime, endTime, gap = 20) => {
    const schedules = [];
    const currentDate = new Date(startDate);
    const end = new Date(endDate);
    const startMinutes = convertTimeStringToMinutes(startTime);
    const endMinutes = convertTimeStringToMinutes(endTime);

    while (currentDate <= end) {
        if (isDateInDays(currentDate, days)) {
            if (startMinutes < endMinutes) {
                const timeSlots = generateTimeSlots(startTime, endTime, gap); 

                schedules.push({
                    date: currentDate.toLocaleDateString('en-US'), 
                    slots: timeSlots 
                });
            }
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return schedules;
};

export const addSchedule = async ({ startDate, endDate, days, startTime, endTime }, doctorId, scheduleId = null) => {
    try {
        const schedules = generateSchedule(startDate, endDate, days, startTime, endTime);
        const requestData = {
            start_time: startTime,
            end_time: endTime,
            start_year: startDate,
            end_year: endDate,
            doctor_id: doctorId,
            days,              
            schedules,        
        };

        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };

        const url = scheduleId
            ? `/schedule/update_schedule/${scheduleId}`
            : `/schedule/add_schedule`;

        const response = await axiosAuthorized[scheduleId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;

    } catch (error) {
        throw error;
    }
};
