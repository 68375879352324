import { axiosAuthorized } from "../../api/config"

export const getHeaderInforSection = async () => {
    try {
        const response = await axiosAuthorized.get(`/headerinfo/get_headerinfo`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addCertificateBanner = async (initialState, icons, id = null) => {
    try {
        const requestData = new FormData();
        Object.entries(initialState).forEach(([key, value]) => {
            requestData.append(key, value);
        });
        icons.forEach((image) => {
            requestData.append("icons", image);
        });
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = id
            ? `/headerinfo/update_headerinfo/${id}`
            : `/headerinfo/add_header`;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteHeaderInfoSection = async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/headerinfo/delete_headerinfo/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}