import { axiosAuthorized } from "../../api/config"

export const getAnalytics = async () => {
    try {
        const response = await axiosAuthorized.get(`/analytics/get_analytics`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addAnalytics = async (initialState, analytic_icon, id = null) => {
    try {
        const requestData = {
            ...initialState,
            analytic_icon
        };

        const requestConfig = {
            headers: { "Content-Type": analytic_icon instanceof File ? "multipart/form-data" : "application/json" },
        };

        const url = id ? `/analytics/update_analytics/${id}` : `/analytics/add_analytics`;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteAnalytics = async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/analytics/delete_analytics/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}