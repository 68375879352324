import React from "react";
import { ContactUsMainSection } from "../../components/ContactUsComponents";

const ContactUs = () => {
  return (
    <div className="w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
        <ContactUsMainSection/>
    </div>
  );
};

export default ContactUs;
