import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound } from "../Common";
import { CiEdit } from "react-icons/ci";
import Table from "../Common/Table/Table";
import { MdOutlineDeleteOutline } from "react-icons/md";
import RegionsModal from "./RegionsModal";
import { deleteRegion, getRegions } from "../../utils/regionsUtils/regionsUtils";

const Regions = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getRegions()
      .then((res) => setDatas(res?.data?.data || []))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [editData, setEditData] = useState(null);
  const delData = (dataId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteRegion(dataId)
      .then((res) => {
        setDatasFn();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <RegionsModal
          setOpen={setOpen}
          setDatasFn={setDatasFn}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Region</span>
        <button
          onClick={() => setOpen(true)}
          className="flex items-center gap-2 px-2 py-1 bg-primary text-white text-sm font-normal rounded-md"
        >
          <span>Add</span>
        </button>
      </div>
      {datas?.length > 0 ? (
        <Table headings={["Icon", "Name", "Edit", "Delete"]}>
          {datas.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                      <img
                        className="rounded-md h-16 w-16 object-cover"
                        src={data?.region_icon}
                        alt="region_icon"
                      />
                    </div>
                  </div>
                </td>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{data?.region_name}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    setEditData(data);
                    setOpen(true);
                  }}
                >
                  <CiEdit color="green" />
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    delData(data?._id);
                  }}
                >
                  <MdOutlineDeleteOutline color="red" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"Regions"} />
      )}
    </div>
  );
};

export default Regions;
