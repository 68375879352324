import React, { useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../hooks';
import { ImageInput, ShowImage, TextInput } from '../Common/FormComponents';
import { vaidatePharmacyForm } from '../../utils/pharmacyUtils/validatePharmacyForm';
import { addPharmacy } from '../../utils/pharmacyUtils/pharmacyUtils';
import { toast } from 'react-toastify';

const PharmacyModal = ({ setOpen, editData,setEditData, setPharmaciesFn }) => {
  const pharmacyInitialState = {
    name: editData?.name || "",
    owner: editData?.owner_name || "",
    email: editData?.email_id || "",
    phone: editData?.phone_number || "",
    department: editData?.department || "",
    category: editData?.category || "",
  };
  const [image, setImage] = useState(editData?.profile_image || null);
  const [pharmacyData, handlePharmacyData, clearForm] = useForm(pharmacyInitialState);
 const [err, setErr] = useState({ global: "" });
 const [loading, setLoading] = useState(false);
 const handleSubmit = (e) => {
   e.preventDefault();
   if (vaidatePharmacyForm(pharmacyData, image, setErr)) {
     setErr({ global: "" });
        setLoading(true);
        addPharmacy(pharmacyData, image, editData?._id)
          .then(res => {
            setErr({ global: "" }); setPharmaciesFn(); setImage(null); clearForm();
            toast(`Pharmacy ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });setEditData(null);  setOpen(false); })
          .catch(err => {
            console.log(err);
            toast(`Pharmacy ${editData ? "edit" : "add"} failed`, {
            type: "error",
            autoClose: 3000,
          });
          })
          .finally(() => setLoading(false));
      }
 };
 return (
   <div className="absolute flex w-full h-fit overflow-y-auto top-0 left-0 bottom-0 justify-center items-center p-4 ">
     <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-fit border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
            <span>{editData ? "Edit Pharmacy" :"Add new Pharmacy"}</span>
            <span className="cursor-pointer" onClick={() => { setOpen(false);setEditData(null); }}><GrFormClose fontSize={16}/></span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
        <TextInput name={"Name"} formName={"name"} data={pharmacyData} handleInput={handlePharmacyData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
        <TextInput name={"Email"} formName={"email"} data={pharmacyData} handleInput={handlePharmacyData} setError={setErr}/>
        <TextInput name={"Phone"} formName={"phone"} data={pharmacyData} handleInput={handlePharmacyData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
        <TextInput name={"Department"} hide={true} formName={"department"} data={pharmacyData} handleInput={handlePharmacyData} setError={setErr}/>
         <TextInput name={"Category"} hide={true} formName={"category"} data={pharmacyData} handleInput={handlePharmacyData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
        <ShowImage image={image} setImage={setImage}/>
        <ImageInput imageName={"Pharmacy Image"} setImage={setImage}/>
        </div>
        {err.global && (<div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">{err.global}</div>)}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center" onClick={() => {  setOpen(false);setEditData(null);  }}>
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center " disabled={loading} onClick={(e) => {handleSubmit(e);}}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
 );
}

export default PharmacyModal