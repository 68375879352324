import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { ImageInput, ShowImage, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addAnalytics } from "../../utils/homeUtils/analyticUtils";

const AnalyticsModal = ({ setOpen, setDatasFn, editData = null, setEditData }) => {
  const [err, setErr] = useState({ global: "" });
  const [image, setImage] = useState(editData?.analytic_icon || null);
  const initialState = {
    count_number: editData?.count_number || "",
    count_text: editData?.count_text || ""
  }
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.count_text.length) return setErr({ global: "Invalid Text" });
    if (!formData.count_number.length) return setErr({ global: "Invalid Number" });
    if (!image) return setErr({ global: "Select Icon" });
    setErr({ global: "" });
    setLoading(true);
    addAnalytics(formData, image, editData?._id)
      .then((res) => {
        clearForm();
        setDatasFn();
        setImage(null);
        setOpen(false);
        setEditData(null);
        toast(`Analytics ${editData ? 'Edited' : 'Added'}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Analytics ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg bg-white rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{"Analytics"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"count_text"}
            name="Text"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            data={formData}
            formName={"count_number"}
            name="Number"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput imageName={"Icon"} setImage={setImage} />
          <ShowImage image={image} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setEditData(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsModal;
