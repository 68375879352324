import React, { useCallback, useEffect, useState } from 'react'
import { SearchInput } from '../Common';
import { AiOutlineMedicineBox } from 'react-icons/ai';
import PharmacyTable from './PharmacyTable';
import PharmacyModal from './PharmacyModal';
import { getPharmacies } from '../../utils/pharmacyUtils/pharmacyUtils';
import { useAuth } from '../../context/useAuth'; 

const PharmacyMainSection = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [pharmacies, setPharmacies] = useState([]);
  const setPharmaciesFn = useCallback(() => {
    getPharmacies()
      .then((res) => setPharmacies(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setPharmaciesFn();
  }, [setPharmaciesFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdPharmacies, setFilteredPharmacies] = useState([]);
  useEffect(() => {
    const filteredData = pharmacies?.filter((pharmacy) => {
      const nameMatch = pharmacy?.name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      // const ownerMatch = pharmacy?.owner_name?.toLowerCase()
      //   ?.includes(searchQuery?.toLowerCase());
      const phoneMatch = pharmacy?.phone_number?.toString()?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const emailMatch = pharmacy?.email_id?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch ||
        // ownerMatch ||
        phoneMatch || emailMatch;
    });
    setFilteredPharmacies(filteredData);
  }, [pharmacies, searchQuery]);
  const user = useAuth().authState.user;
  return (
    <>
    {
        open && <PharmacyModal setOpen={setOpen} editData={editData} setEditData={setEditData} setPharmaciesFn={setPharmaciesFn} />
    }
      <div className=" flex w-full items-center gap-5 md:gap-0">
        <div className="flex w-fit h-fit text-xl font-semibold">Pharmacies</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput placeholder={"Search by Name, Phone, Email"} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col ">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            disabled={!user.access?.includes("Edit")}
            className={`bg-primary text-white   text-sm rounded-md gap-2 p-2 items-center  ${
              !user.access?.includes("Edit") ? "hidden" : "flex"
            }`}
            onClick={() => setOpen(true)}
          >
            <span>
              <AiOutlineMedicineBox />
            </span>
            <span>Pharmacy</span>
          </button>
        </div>
        <PharmacyTable user={user} setOpen={setOpen} pharmacies={filterdPharmacies} setEditData={setEditData} setPharmaciesFn={setPharmaciesFn}/>
      </div>
    </>
  );
}

export default PharmacyMainSection