export const validateCareerForm = (initialState, catId, keySkills,file, setErr) => {
    
    if (!initialState.job_title.trim().length) {
        setErr({ global: "Invalid Job Title" });
        return false;
    }

    if (catId === 0) {
        setErr({ global: "Invalid Job Category" });
        return false;
    }

    if (!initialState.qualification.trim().length) {
        setErr({ global: "Invalid Qualification" });
        return false;
    }

    if (!initialState.experience.trim().length) {
        setErr({ global: "Invalid Experience" });
        return false;
    }

    if (!initialState.vacancy.trim().length) {
        setErr({ global: "Invalid Vanancy" });
        return false;
    }

    if (!initialState.salary.trim().length) {
        setErr({ global: "Invalid Salary" });
        return false;
    }

    if (!keySkills.length) {
        setErr({ global: "Add At Least One Skill" });
        return false;
    }
    
    return true;
}