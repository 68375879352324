import React, { useCallback, useEffect, useState } from "react";
import HeaderSectionModal from "./HeaderSectionModal";
import { getHeaderInforSection } from "../../utils/homeUtils/headerInfoUtils";

const HeaderSection = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getHeaderInforSection()
      .then((res) => setDatas(res.data.data[0] || null))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative mt-5">
      {open && (
        <HeaderSectionModal
          setDatasFn={setDatasFn}
          setOpen={setOpen}
          editData={datas}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Header Info Section</span>
        <button
          onClick={() => {
            setOpen(true);
          }}
          className="flex items-center gap-2 bg-primary px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Update</span>
        </button>
      </div>
    </div>
  );
};

export default HeaderSection;
