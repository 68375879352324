import { TiArrowLeft } from "react-icons/ti";
import "./OtpFiled.css";
import { useState, useRef, useEffect } from 'react';

const OtpInput = ({
  length = 6,
  handleSubmit,
  loading,
  setErr,
  err,
  clearForm,
  setForgot,
  setEmailVerfied,
  handleResendOtp,  
  timeLeft,
  setTimeLeft
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const [canResend, setCanResend] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0]?.focus();
    }
  }, []);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true);
    }
  }, [timeLeft]);

  const handleChange = (index, event) => {
    const value = event.target.value.slice(-1);
    if (isNaN(Number(value))) return;
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (index < length - 1 && value !== "" && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }

    const combinedOtp = updatedOtp.join("");
    if (combinedOtp.length === length) {
      handleSubmit(combinedOtp);
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index]?.setSelectionRange(1, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")]?.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (
      index > 0 &&
      event.key === "Backspace" &&
      !otp[index] &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleResend = () => {
    if (canResend) {
      handleResendOtp();
      setOtp(new Array(length).fill(""));
      setCanResend(false);
    } 
  };

  return (
    <div className="flex flex-col gap-5 items-center w-full h-full justify-center">
      <div>
        {otp.map((item, index) => (
          <input
            key={index}
            type="text"
            value={item}
            ref={(input) => (inputRefs.current[index] = input)}
            className="otp-input"
            onChange={(event) => handleChange(index, event)}
            onClick={() => handleClick(index)}
            onKeyDown={(event) => handleKeyDown(index, event)}
          />
        ))}
      </div>

      <div className="flex items-center gap-4 text-sm">
        {timeLeft > 0 ? (
          <span>
            Resend OTP in {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, "0")}
          </span>
        ) : (
          <button
            onClick={handleResend}
            className="text-blue-500 hover:text-blue-700"
            disabled={!canResend}
          >
            Resend OTP
          </button>
        )}
      </div>

      <span
        onClick={() => {
          setErr({ global: "" });
          clearForm();
          setEmailVerfied(false);
        }}
        className="w-full flex gap-1 items-center justify-center text-sm cursor-pointer hover:text-primary"
      >
        <TiArrowLeft color="black" /> back
      </span>

      {err.global && (
        <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
          {err.global}
        </div>
      )}

      <button
        disabled={loading}
        onClick={() => {
          handleSubmit(otp.join("").toString());
        }}
        className="w-fit h-fit py-1 justify-center items-center px-5 bg-primary text-white rounded-md"
      >
        {loading ? "Loading..." : "Submit"}
      </button>
    </div>
  );
};

export default OtpInput;
