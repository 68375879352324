import React, { useEffect, useState } from 'react';
import { IoIosClose } from "react-icons/io";
import TimeInput from "../../components/Common/FormComponents/TimeInput/TimeInput";
import { useForm } from "../../hooks";
import { toast } from 'react-toastify';
import { addSchedule, getSchedule } from '../../utils/scheduleUtils/sheduleUtils';

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const generateYears = (start, end) => {
  const years = [];
  for (let year = start; year <= end; year++) {
    years.push(year);
  }
  return years;
};

const ScheduleModal = ({ setOpen, user }) => {
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [timeData, handleTimeData, clearForm, setValues] = useForm({
    startTime: "",
    endTime: "",
  });

  const fetchSchedules = async (doctorId) => {
    try {
      const response = await getSchedule(doctorId);
      const schedules = response.data.data[0]
      if (schedules) {
        setSchedule(schedules);
        setStartYear(schedules.start_year.split('/')[0]);
        setEndYear(schedules.end_year.split('/')[0]);
        setSelectedDays(schedules.days);
        setValues({ startTime: schedules.start_time, endTime: schedules.end_time, });
      } else {
        toast(`Schedules not added`, { type: "warning", autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
      toast(`Error fetching schedules`, { type: "error", autoClose: 3000 });
    }
  };

  const handleDayChange = (day) => {
    setSelectedDays(prevDays =>
      prevDays.includes(day)
        ? prevDays.filter(d => d !== day)
        : [...prevDays, day]
    );
  };

  const handleSubmit = () => {
    if (!startYear || !endYear || selectedDays.length === 0 || !timeData.startTime || !timeData.endTime) {
      setErr({ global: "Please fill in all fields" });
      return;
    }

    const scheduleData = {
      startDate: `${startYear}/01/01`,
      endDate: `${endYear}/12/31`,
      days: selectedDays,
      startTime: timeData.startTime,
      endTime: timeData.endTime,
    };

    setLoading(true);
    setErr({ global: "" });

    // Call the API to add or update the schedule
    addSchedule(scheduleData, user?._id, schedule?._id)
      .then(res => {
        toast(`Schedule Updated`, { type: "success", autoClose: 3000 });
        setOpen(false);
      })
      .catch(err => {
        console.log(err);
        toast(`Schedule Update Failed`, { type: "error", autoClose: 3000 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user?._id) {
      fetchSchedules(user._id);
    }
  }, [user]);

  const currentYear = new Date().getFullYear();
  const years = generateYears(2000, currentYear);

  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 min-h-screen">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full my-auto border shadow-lg rounded-md gap-5 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full justify-between h-fit text-sm">
          <span className="font-semibold">Schedule</span>
          <IoIosClose fontSize={22} className="cursor-pointer" onClick={() => setOpen(false)} />
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex gap-5">
            <div className="lable-and-filed">
              <label htmlFor="Start Year" className="label">
                Start Year <span className="required">*</span>
              </label>
              <select
                value={startYear}
                onChange={(e) => setStartYear(e.target.value)}
                className="select-option"
              >
                <option value="">Select Start Year</option>
                {years.sort((a, b) => b - a).map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div className="lable-and-filed">
              <label htmlFor="End Year" className="label">
                End Year <span className="required">*</span>
              </label>
              <select
                value={endYear}
                onChange={(e) => setEndYear(e.target.value)}
                className="select-option"
              >
                <option value="">Select End Year</option>
                {years.sort((a, b) => b - a).map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label>Select Days</label>
            <div className="grid grid-cols-3 gap-2">
              {daysOfWeek.map((day) => (
                <div key={day} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={selectedDays.includes(day)}
                    onChange={() => handleDayChange(day)}
                  />
                  <span>{day}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex gap-5">
            <TimeInput
              data={timeData}
              handleInput={handleTimeData}
              name="Start Time"
              formName="startTime"
              setError={setErr}
            />
            <TimeInput
              data={timeData}
              handleInput={handleTimeData}
              name="End Time"
              formName="endTime"
              setError={setErr}
            />
          </div>

          {/* <div className="flex items-center gap-2">
            <label htmlFor="isOnLeave" className="font-medium">
              Mark as Unavailable
            </label>
            <input
              id="isOnLeave"
              type="checkbox"
              checked={isOnLeave}
              onChange={() => setIsOnLeave(!isOnLeave)}
              className="form-checkbox h-4 w-4 text-primary"
            />
          </div> */}

          {err.global && (
            <div className="text-red-600 text-sm text-center">
              {err.global}
            </div>
          )}

          <div className="flex gap-5">
            <button
              onClick={() => setOpen(false)}
              className="w-full rounded-md text-primary border-primary border bg-white px-2 py-1"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              disabled={loading}
              className="w-full rounded-md bg-primary text-white px-2 py-1"
            >
              Schedule
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleModal;
