import { axiosAuthorized } from "../../api/config";

export const getLeads = async () => {
    try {
        const response = await axiosAuthorized.get("/booking/get_booking");
        return response;
    } catch (error) {
        throw error;
    }
}


export const getContactFormSubmissions = async () => {
    try {
        const response = await axiosAuthorized.get("/getcontactform");
        return response;
    } catch (error) {
        throw error;
    }
}

export const udpateLeadValid = async (valid, leadId) => {
    try {
        const response = await axiosAuthorized.put(`/booking/update_booking/${leadId}`, { valid }, { headers: { "Content-Type": "application/json" } });
        return response;
    } catch (error) {
        throw error;
    }
}

export const udpateRemark = async (remarks, leadId) => {
    try {
        const response = await axiosAuthorized.put(`/booking/update_booking/${leadId}`, { remarks }, { headers: { "Content-Type": "application/json" } });
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteLead = async (leadId) => {
    try {
        const response = await axiosAuthorized.delete(`/booking/delete_booking/${leadId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteContactForm = async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/contactform/delete_contactform/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}