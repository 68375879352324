import React from 'react'
import { GrFormClose } from 'react-icons/gr';
import { TextAreaInput, TextInput } from '../Common/FormComponents';

const ContactUsModal = ({ editData, setEditData }) => {
  return (
    <div className="absolute flex w-full min-h-screen overflow-y-auto top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-fit border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{"Contact Submission"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"name"}
            name={"Name"}
            hide={true}
            data={editData}
            setError={()=>{}}
            disabled={true}
          />
          <TextInput
            formName={"email"}
            name={"Email"}
            hide={true}
            data={editData}
            setError={() => { }}
            disabled={true}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            formName={"message"}
            name={"Message"}
            hide={true}
            data={editData}
            setError={() => { }}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
}

export default ContactUsModal