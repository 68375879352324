import "./navbar.css"
import React from "react";
import { NavLink } from "react-router-dom";
import { NavDrop } from "./navdrop";
import { useAuth } from "../../context/useAuth";

export const NavBar = () => {
  const user = useAuth().authState.user;
  const links = user && user.pages ? user.pages.map((page) => ({
    to: `/${page === "Doctors" ? "" : page.toLowerCase()}`,
    destination: `${page}`,
  })) : [];
  // const links = useMemo(
  //   () => [
  //     // { to: "/", destination: "Hospitals" },
  //     { to: "/", destination: "Doctors" },
  //     { to: "/departments", destination: "Departments" },
  //     { to: "/home", destination: "Home" },
  //     // { to: "/hospital-listing", destination: "HospitalListing" },
  //     // { to: "/users", destination: "Users" },
  //     { to: "/treatments", destination: "Treatments" },
  //     // { to: "/labs", destination: "Labs" },
  //     // { to: "/vendors", destination: "Vendors" },
  //     // { to: "/services", destination: "Services" },
  //     // { to: "/leads", destination: "Leads" },
  //     // { to: "/user-dashboard", destination: "UserDashboard" },
  //     // { to: "/refer", destination: "Refer" },
  //     // { to: "/sales", destination: "Sales" },
  //     // { to: "/sales-forms", destination: "SalesForms" },
  //     { to: "/branches", destination: "Branches" },
  //     // { to: "/numbers", destination: "MediDocsNumbers" },
  //     // { to: "/cta-icons", destination: "CTAIcons" },
  //     { to: "/blogs", destination: "Blogs" },
  //     { to: "/about", destination: "About" },
  //     { to: "/career", destination: "Career" },
  //     // { to: "/admins", destination: "Admins" }
  //   ],
  //   []
  // )
  return (
    <>
      <div className="navbar">
        <div className="logo-container">
          <div className="logo">
            <img src="/assets/images/logo.png" alt="logo" />
          </div>
          {
            <div className="hidden lg:flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="67"
                viewBox="0 0 3 67"
                fill="none"
              >
                <ellipse
                  cx="1.5"
                  cy="33.5"
                  rx="1.5"
                  ry="33.5"
                  fill="url(#paint0_radial_81_19)"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial_81_19"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1.5 33.5) rotate(90) scale(33.5 1.5)"
                  >
                    <stop offset="0.255208" />
                    <stop offset="1" stopOpacity="0" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          }
        </div>
        <div className="navlinks ">
          {links.slice(0,6).map(({ to, destination }, index) => (
            <NavLink to={to} key={index} className="navlink-class">
              <div>{destination}</div>
              <div className="active-dot"></div>
            </NavLink>
          ))}
        </div>
        <div className="earnings-container">
          <NavDrop user={user} links={links} />
        </div>
      </div>
    </>
  );
};

