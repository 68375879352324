import React, { useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import { TextInput } from "../Common/FormComponents";
import { addCategory } from "../../utils/careerUtils/careerUtils";
import { toast } from "react-toastify";

const CategoryModal = ({ setOpen, editData = null, setEditData, setJobsFn }) => {
  const initialState = {
    job_category: editData?.job_category || ""
  };
  const [err, setError] = useState({ global: "", keySkill: "" });
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.job_category.length !== 0) {
      setError({ global: "", keySkill: "" });
      setLoading(true);
      addCategory(formData, editData?._id)
        .then((res) => {
          clearForm();
          toast(`Category ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          setJobsFn();
          setOpen(false);
        })
        .catch((err) => {
          toast(`Category ${editData ? "edit" : "add"} failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError({ global: "Invalid Category" });
      return;
    }
  };
  return (
    <div className="modal-container min-h-screen">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Category" : "Add New Category"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Category"}
            formName={"job_category"}
            setError={setError}
          />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
