import { axiosAuthorized } from "../../api/config";

export const getContactUs = async () => {
    try {
        const response = await axiosAuthorized.get(`/getcontact`);
        return response.data.data[0];
    } catch (error) {
        throw error;
    }
}

export const addContactUs = async (initialState,hospital_location,banner_images,id = null) => {
    try {
        const requestData = {
            ...initialState,
            banner_images,
            hospital_location
        }
        const url = id ? `/contact/update_contact/${id}` : `/contact/add_contact`
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const reponse = await axiosAuthorized[id ? `put` : `post`](url, requestData, requestConfig);
        return reponse;
    } catch (error) {
        throw error;
    }
}

export const addContactUsNumbers = async (initialState,id = null) => {
    try {
        const requestData = {
            ...initialState,
        }
        const url = id ? `/number/update_number/${id}` : `/number/add_number`
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const reponse = await axiosAuthorized[id ? `put` : `post`](url, requestData, requestConfig);
        return reponse;
    } catch (error) {
        throw error;
    }
}               

export const getContactUsNumbers = async () => {
    try {
        const response = await axiosAuthorized.get(`/getnumber`);
        return response.data.data[0];
    } catch (error) {
        throw error;
    }
}

export const getContactUsContent = async () => {
    try {
        const response = await axiosAuthorized.get(`/getcontact`);
        return response.data.data[0];
    } catch (error) {
        throw error;
    }
}

export const addContactUsContent = async (initialState,id = null) => {
    try {
        const requestData = {
            ...initialState,
        }
        const url = id ? `/contact/update_contact/${id}` : `/contact/add_contact`
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const reponse = await axiosAuthorized[id ? `put` : `post`](url, requestData, requestConfig);
        return reponse;
    } catch (error) {
        throw error;
    }
}