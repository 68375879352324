export const vaidatePharmacyForm = (initialState, image, setError) => {
    const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

    if (!initialState.name.trim().length) {
        setError({ global: "Invalid Name" });
        return false;
    }

    // if (!initialState.owner.trim().length) {
    //     setError({ global: "Invalid Owner Name" });
    //     return false;
    // }

    if (!emailPattern.test(initialState.email)) {
        setError({ global: "Invalid Email" });
        return false;
    }

    if (!phoneNumberPattern.test(initialState.phone)) {
        setError({ global: "Invalid Phone Number" });
        return false;
    }

    // if (!initialState.department.trim().length) {
    //     setError({ global: "Invalid Department" });
    //     return false;
    // }
    // if (!initialState.category.trim().length) {
    //     setError({ global: "Invalid Category" });
    //     return false;
    // }

    if (!image) {
        setError({ global: "Please Select Image" });
        return false;
    }
    return true;
}