import React, { useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import { ImageInput, ShowImage, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { validateMemberForm } from "../../utils/aboutUsUtils/validateAboutUsForm";
import { addMember } from "../../utils/aboutUsUtils/aboutUsUtils";
const OurAdversoryBoardModal = ({
  setOpen,
  editData = null,
  setEditData,
  setOurAdversoryBoardFn,
}) => {
  const initialState = {
    team_name: editData?.team_name || "",
    team_designation: editData?.team_designation || "",
  };
  const [err, setError] = useState({ global: "" });
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [team_image, setProfileImage] = useState(editData?.team_image || null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateMemberForm(formData, team_image, setError)) {
      setError({ global: "" });
      setLoading(true);
      addMember(
        formData,
        team_image,
        editData?._id
      )
        .then((res) => {
          clearForm();
          setProfileImage(null);
          toast(`Member ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          setOurAdversoryBoardFn();
          setOpen(false);
        })
        .catch((err) => {
          toast(`Member ${editData ? "edit" : "add"} failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        className=" flex  w-fit overflow-y-auto bg-white max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="modal-header">
          <span>{editData ? "Edit Member" : "Add New Member"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Name"}
            formName={"team_name"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Designation"}
            formName={"team_designation"}
            setError={setError}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          {
            <>
              <ShowImage image={team_image} setImage={setProfileImage} />
              <ImageInput
                image={team_image}
                setImage={setProfileImage}
                imageName={"Member Image"}
              />
            </>
          }
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurAdversoryBoardModal;
