import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  AddButton,
  ImageInput,
  ShowImage,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addCertificateBanner } from "../../utils/homeUtils/headerInfoUtils";

const HeaderSectionModal = ({
  setOpen,
  setDatasFn,
  editData = null,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState(editData?.icons || []);
  const handleImages = (action, idx = 0) => {
    setErr({ global: "" });
    if (action === "add") {
      if (!image) {
        return setErr({ global: "Please Select Image" });
      } else {
        setImages([...images, image]);
        setImage(null);;
      }
    } else if (action === "remove") {
      setImages(images.filter((_, index) => index !== idx));
    }
  };
  const initialsState = {
    title: editData?.title || "",
    description: editData?.description || "",
  };
  const [formData, handleFormData] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (!formData.description.trim().length)
      return setErr({ global: "Invalid Description" });
    if (!images.length) return setErr({ global: "Please Select At Leaset One Icon" });
    setErr({ global: "" });
    setLoading(true);
    addCertificateBanner(formData, images, editData?._id)
      .then((res) => {
        setDatasFn();
        setOpen(false);
        toast(`Header Section ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(
          `Header Section ${editData ? "Edit Failed" : "Add Failed"}`,
          {
            type: "error",
            autoClose: 3000,
          }
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg bg-white rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{"Header Section"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"title"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextAreaInput
            data={formData}
            formName={"description"}
            name="Description"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {images?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {images?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <div className={`flex w-10 h-10 rounded overflow-hidden`}>
                  <img
                    src={
                      item instanceof File ? URL.createObjectURL(item) : item
                    }
                    alt={"certificate_banner_image"}
                    className={`rounded object-cover`}
                  />
                </div>
                <button
                  onClick={() => {
                    handleImages("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"Icon"}
            setImage={setImage}
          />
          <ShowImage image={image} setImage={setImage} />
          {images?.length >= 3 ? null : <AddButton handleAdd={handleImages} />}
        </div>
        {images?.length >= 3 && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-end items-center font-semibold">
            maximum - 3 Images
          </div>
        )}
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSectionModal;
