export const validateMemberForm = (initialState , image, pastCompany,  setErr) => {
    if (!initialState.team_name.trim().length) {
        setErr({ global: "Invalid Name" });
        return false;
    }

    if (!initialState.team_designation.trim().length) {
        setErr({ global: "Invalid Designation" });
        return false;
    }
    
    if (!image) {
        setErr({ global: "Select Member Image" });
        return false;
    }
    return true;
}
export const validateIconForm = (initialState , image,  setErr) => {

    if (!initialState.icon_name.trim().length) {
        setErr({ global: "Invalid Name" });
        return false;
    }
    
    if (!image) {
        setErr({ global: "Select Profile Image" });
        return false;
    }

    return true;
}