import React, { useCallback, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { SearchInput } from "../Common";
import { BsArrowLeftShort } from "react-icons/bs";
import CareerTable from "./CareerTable";
import CareerModal from "./CareerModal";
import { getAllJobs, getCategories } from "../../utils/careerUtils/careerUtils";
import ApplicantsList from "./ApplicantsList";
import CategoryModal from "./CategoriesModal";
import CategoriesTable from "./CategoriesTable";
import { useAuth } from "../../context/useAuth";

const CareerMainSection = () => {
  const [open, setOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [editData, setEditData] = useState(null);
  const [editCategory, setEditCategory] = useState(null);
  const [job, setJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [categories, setCategories] = useState([]);
  const setJobsFn = useCallback(() => {
    getAllJobs()
      .then((res) => setJobs(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setJobsFn();
  }, [setJobsFn]);
  const setCategoriesFn = useCallback(() => {
    getCategories()
      .then((res) => setCategories(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setCategoriesFn();
  }, [setCategoriesFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = jobs?.filter((job) => {
      const nameMatch = job?.job_title
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const qualificationMatch = job?.qualification
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || qualificationMatch;
    });
    setFilteredData(filteredData);
  }, [jobs, searchQuery]);
  const user = useAuth().authState.user;
  return (
    <>
      {open && (
        <CareerModal
          setEditData={setEditData}
          setJobsFn={setJobsFn}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      {openCategory && (
        <CategoryModal
          setEditData={setEditCategory}
          setJobsFn={setCategoriesFn}
          setOpen={setOpenCategory}
          editData={editCategory}
        />
      )}
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">Career</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search By Job Title, Qualification"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            disabled={!user?.access?.includes("Edit")}
            className={` text-white ${!user?.access?.includes("Edit") ? "hidden" : "flex"
              } text-sm rounded-md gap-2 p-2 bg-primary items-center`}
            onClick={() => setOpen(true)}
          >
            <span>
              <FaUserPlus />
            </span>
            <span>Job</span>
          </button>
        </div>
        {job && (
          <button
            className={`w-fit flex text-sm rounded-md gap-2 p-2 active:text-primary  items-center`}
            onClick={() => setJob(null)}
          >
            <span>
              <BsArrowLeftShort />
            </span>
            <span>Back</span>
          </button>
        )}
        {job ? (
          <ApplicantsList job={job} />
        ) : (
          <CareerTable
            jobs={filterdData}
            setJobsFn={setJobsFn}
            setEditData={setEditData}
            setOpen={setOpen}
            user={user}
            setJob={setJob}
          />
        )}
      </div>
      <div className="flex w-full justify-between items-center h-fit">
        <span className="font-semibold text-base">Categories</span>
        <button
          disabled={!user?.access?.includes("Edit")}
          className={` text-white ${!user?.access?.includes("Edit") ? "hidden" : "flex"
            } text-sm rounded-md gap-2 p-2 bg-primary items-center`}
          onClick={() => setOpenCategory(true)}
        >
          <span>
            <FaUserPlus />
          </span>
          <span>Category</span>
        </button>
      </div>
      <CategoriesTable
        categories={categories}
        setCategoriesFn={setCategoriesFn}
        setEditData={setEditCategory}
        setOpen={setOpenCategory}
        user={user}
      />
    </>
  );
};

export default CareerMainSection;
