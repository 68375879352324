import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "../../hooks";
import { AddButton, TextInput } from "../Common/FormComponents";
import JoditEditor, {Jodit} from "jodit-react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  addDepartmentQualities,
  getDepartmentQualities,
} from "../../utils/departmentUtils/departmentUtils";

const config = {
  readonly: false,
  placeholder: "Description",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const DepartmentQualities = ({ editData }) => {
  const editor = useRef(null);
  const [data, setData] = useState(null);
  const [titleData, handleTitleData, clearTitleData, setValues] = useForm({
    title: ""
  })
  const [description, setDescription] = useState("<p></p>");
  const [qualities, setQualities] = useState([]);
  const [formData, handleFormData, clearForm] = useForm({
    qualities: "",
  });
  const handleQualities = (action, idx = 0) => {
    if (action === "add") {
      if (
        !formData.qualities.trim().length
      ) {
        return setErr({ global: "Please Enter Quality" });
      } else {
        setQualities([
          ...qualities,
          formData.qualities,
        ]);
        clearForm();
      }
    } else if (action === "remove") {
      setQualities(qualities.filter((_, index) => index !== idx));
    }
  };
  const setDatasFn = useCallback(() => {
    getDepartmentQualities(editData?._id)
      .then((res) => {
        setData(res?.data);
        setDescription(res?.data?.description || "<p></p>");
        setValues({ title: res?.data?.title || "" });
        setQualities(res?.data?.qualities || []);
      })
      .catch((err) => console.error(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData?._id]);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!titleData.title.trim().length) {
    //   return setErr({ global: "Invalid Title" });
    // }
    // if (!description.replace(/<[^>]*>/g, "").trim().length) {
    //   return setErr({ global: "Invalid Description" });
    // }
    // if (!qualities.length) {
    //   return setErr({ global: "Add At Least One Item" });
    // }
    setLoading(true);
    addDepartmentQualities(
      editData?._id,
      titleData.title,
      description,
      qualities,
      data?._id
    )
      .then((res) => {
        setDatasFn();
        setErr({ global: "" });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full h-fit items-center justify-between font-semibold">
        <span>{"Add Departments Qualities"}</span>
      </div>
      <div className="feild-conatainer ">
        <TextInput
          hide={true}
          data={titleData}
          handleInput={handleTitleData}
          name={"Title"}
          formName={"title"}
          setError={setErr}
        />
      </div>
      <div className="flex flex-col w-full gap-0.5">
        <label htmlFor={"Description"} className="label">
          {"Description"}
        </label>
        <div className="bg-white text-black">
          <JoditEditor
            ref={editor}
            value={description}
            config={config}
            tabIndex={1}
            onBlur={(newContent) =>
              setDescription(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              )
            }
          />
        </div>
      </div>
      {qualities?.length > 0 && (
        <div className="flex w-full gap-1 flex-wrap">
          {qualities?.map((item, idx) => (
            <div
              className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
              key={idx}
            >
              <span>{item}</span>
              <button
                onClick={() => {
                  handleQualities("remove", idx);
                }}
              >
                <AiOutlineCloseCircle fontSize={14} color="red" />
              </button>
            </div>
          ))}
        </div>
      )}
      <div className="feild-conatainer ">
        <TextInput
          data={formData}
          handleInput={handleFormData}
          name={"Quality"}
          formName={"qualities"}
          setError={setErr}
        />
        <AddButton handleAdd={handleQualities} />
      </div>
      <div className="feild-conatainer">
        <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          <button
            disabled={loading}
            className="p-1 border rounded-md text-black -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
    </div>
  );
};

export default DepartmentQualities;
