import { axiosAuthorized } from "../../api/config"

export const getVideoTestimonial = async () => {
    try {
        const response = await axiosAuthorized.get(`/gettestinomal`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addVideoTestimonial = async (initialState, postedby_image, id = null) => {
    try {
        const requestData = {
            ...initialState,
            postedby_image
        };

        const requestConfig = {
            headers: { "Content-Type": postedby_image instanceof File ? "multipart/form-data" : "application/json" },
        };

        const url = id ? `/testinomal/update_testinomal/${id}` : `/testinomal/add_testinomal`;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteVideoTestimonial = async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/testinomal/delete_testinomal/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}