import React, { useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { ImageInput, ShowImage, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import JoditEditor, { Jodit } from "jodit-react";
import { addAdvertise } from "../../utils/homeUtils/advertiseUtils";

const config = {
  readonly: false,
  placeholder: "Description",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const AdvertiseModal = ({ setOpen, setDatasFn, editData = null, setEditData }) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [advertise_image, setAdvertiseImage] = useState(editData?.advertise_image || null);
  const initialsState = {
    title: editData?.title || "",
    slug: editData?.slug || ""
  };
  const editor = useRef(null);
  const [descrption, setDescrption] = useState(editData?.descrption || "<p></p>");
  const [formData, handleFormData] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (!descrption.replace(/<[^>]*>/g, "").trim().length)
      return setErr({ global: "Invalid Description" });
    if (editData && !formData.slug.length) return setErr({ global: "Invalid Slug" });
    if (!advertise_image)
      return setErr({ global: "Please Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addAdvertise(formData, descrption, advertise_image, editData?._id)
      .then((res) => {
        setDatasFn();
        setAdvertiseImage(null);
        setOpen(false);
        toast(`Advertise ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Advertise ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        className=" flex  w-full max-w-screen-md overflow-y-auto max-h-[400px] border shadow-lg bg-white rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Advertise"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"title"}
            name="Title"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Description"} className="label">
            {"Description"} <span className="text-red-600">*</span>
          </label>
          <div className="bg-white text-black rounded-md">
            <JoditEditor
              ref={editor}
              value={descrption}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setDescrption(
                  newContent.replace(
                    /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                    '<td style="border: 1px solid black;" >'
                  )
                );
              }}
            />
          </div>
        </div>
        {editData && <div className="flex w-full h-fit justify-between gap-5 ">
          <TextInput
            data={formData}
            formName={"slug"}
            name="Slug"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>}
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput
            imageName={"Advertise Image"}
            setImage={setAdvertiseImage}
          />
          <ShowImage image={advertise_image} setImage={setAdvertiseImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertiseModal;
